import { AppState, HomePageTelAvivAppState } from '../../../initialStateRegistration';
import {
  HomePageTelAvivMinorSections,
  HomePageTelAvivMinorSectionsWithArticlesOnly,
  HomePageTelAvivTopSection,
} from './homePageTelAviv.initialState';

export const getHomePage = (state: AppState) => (state as HomePageTelAvivAppState).template;

export const getSectionDataByKey = (key: HomePageTelAvivTopSection | HomePageTelAvivMinorSections) => (state: AppState) => getHomePage(state)[key];

export const getSectionArticlesByKey = (key: HomePageTelAvivTopSection | HomePageTelAvivMinorSections) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: Exclude<HomePageTelAvivMinorSections, HomePageTelAvivMinorSectionsWithArticlesOnly>) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getMoreButtonTextByKey = (key: Exclude<HomePageTelAvivMinorSections, HomePageTelAvivMinorSectionsWithArticlesOnly>) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMoreButtonSvgByKey = (key: Exclude<HomePageTelAvivMinorSections, HomePageTelAvivMinorSectionsWithArticlesOnly>) => (state: AppState) => getHomePage(state)[key].moreButtonSvg;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;
