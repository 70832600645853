import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  FullWidthOTC,
  MEDIA_BREAKPOINTS,
  OneHC,
  OneHCThreeVC,
  OneThreeOTC,
  SecondarySectionTitle,
  Section,
  SectionHeader,
  SectionWithTitle,
  ThreeHC,
  ThreeVC,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { Page } from '../Page';
import {
  getMetadataDescription,
  getMetadataTitle,
  getMoreButtonSvgByKey,
  getMoreButtonTextByKey,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getMetadataImage,
  getHreflangData,
} from '../../store/template/homePage/homePageTelAviv/homePageTelAviv.selectors';
import { getAdSlotId, getAdSlotOnSuccess } from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';

interface HomePageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const cardsSectionArticles = getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION)(state) || [];
  const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1)(state) || [];
  const contentSection2Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2)(state) || [];
  const contentSection3Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3)(state) || [];
  return [
    ...topSectionArticles,
    ...cardsSectionArticles,
    ...contentSection1Articles,
    ...contentSection2Articles,
    ...contentSection3Articles,
  ];
};

export const HomePageTelAvivComponent: React.FunctionComponent<HomePageProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageTelAvivContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * 3}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    display: 'grid',
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
  },
});

export const HomePageTelAvivContentConnected: React.FunctionComponent = () => {
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
  const cardsSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CARDS_SECTION));
  const contentSection1DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection3DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection4DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection4MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_4));
  const contentSection5DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const contentSection5MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_5));
  const articles = useSelector(getArticlesInTemplate);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const belowSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const pageImage = useSelector(getMetadataImage);
  const hreflangData = useSelector(getHreflangData) || [];
  const style = createStyle();

  return (
    <React.Fragment>
      <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent">
            <div className={css(style.sectionsWrapper)}>

              <Section themeConfigurationPropKey="firstSectionMaxWidth">
                <OneThreeOTC cards={topSectionArticles} />
              </Section>
              <SectionWithTitle>
                <Ad id={topAdId} onSuccess={topAdOnSuccess} />
                <SectionHeader
                  paddingFactors={{ large: 1, medium: 0.5, small: 1 }}
                  moreButtonSvg={cardsSectionMoreButtonSvg}
                  moreButtonText={cardsSectionMoreButtonText}
                >
                  <SecondarySectionTitle>
                    {cardsSectionDisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneHCThreeVC cards={cardsSectionArticles} paddingFactors={{ large: 1, medium: 0.5, small: 1 }} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  paddingFactors={{ large: 1, medium: 0.5, small: 1 }}
                  moreButtonSvg={contentSection1MoreButtonSvg}
                  moreButtonText={contentSection1MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection1DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneHC cards={contentSection1Articles} paddingFactors={{ large: 1, medium: 0.5, small: 1 }} />
              </SectionWithTitle>

              <Section themeConfigurationPropKey="firstSectionMaxWidth">
                <FullWidthOTC cards={contentSection2Articles} />
              </Section>

              <SectionWithTitle>
                <Ad id={belowSectionAdId} onSuccess={belowSectionAdOnSuccess} />
                <SectionHeader
                  paddingFactors={{ large: 1, medium: 0.5, small: 1 }}
                  moreButtonSvg={contentSection3MoreButtonSvg}
                  moreButtonText={contentSection3MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection3DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ThreeVC cards={contentSection3Articles} paddingFactors={{ large: 1, medium: 0.5, small: 1 }} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  paddingFactors={{ large: 1, medium: 0.5, small: 1 }}
                  moreButtonSvg={contentSection4MoreButtonSvg}
                  moreButtonText={contentSection4MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection4DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ThreeHC cards={contentSection4Articles} paddingFactors={{ large: 1, medium: 0.5, small: 1 }} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  paddingFactors={{ large: 1, medium: 0.5, small: 1 }}
                  moreButtonSvg={contentSection5MoreButtonSvg}
                  moreButtonText={contentSection5MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection5DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneHCThreeVC cards={contentSection5Articles} paddingFactors={{ large: 1, medium: 0.5, small: 1 }} />
              </SectionWithTitle>

            </div>
            <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
