import { HomePageTelAviv as registration } from '../templates';
import * as React from 'react';
import { hydrate } from 'react-dom';
import { StyleSheet } from 'aphrodite/no-important';
import { CoreParams, initCore } from 'mm-client-core';
import { Store } from 'redux';
import { createStoreFromPreloadedState } from '../store/storeFromPreloadedState';
import { ConsoleReporter } from '../errorReporting/consoleReporter';
import { markPerformance } from '../../performance';
import 'intersection-observer';
import { initClientApiForReadEvents, initClientApiForWriteEvents } from './clientAPI';
import { TemplateRegistration } from '../templates/templateRegistration';
import { initStoreErrorReporter } from '../store/storeFromRequestParamsValidation';

const isEmptyObject = (obj: Record<string, any>) => Object.keys(obj).length === 0;

// eslint-disable-next-line
const {component, clientAPIEvents, getCoreParams, rootReducer} =
  // @ts-ignore TS2339
  // eslint-disable-next-line
  registration as TemplateRegistration;

markPerformance('bundle-start');
// @ts-ignore TS2339
const state = window.__PRELOADED_STATE__;
const store = createStoreFromPreloadedState(state, rootReducer);
initClientApiForReadEvents(clientAPIEvents, store);

const coreParams = getCoreParams(state);
if (!isEmptyObject(coreParams)) {
  initCore(coreParams as CoreParams, new ConsoleReporter());
}

const errorProviderApp = new ConsoleReporter();
initStoreErrorReporter(errorProviderApp);

function initReactApp(target: Element | null, appStore: Store) {
  const Page = component;
  // @ts-ignore
  StyleSheet.rehydrate(window.__RENDERED_CLASS_NAMES__);
  // eslint-disable-next-line
  hydrate(<Page store={appStore}
    errorProvider={errorProviderApp}
  />, target, () => initClientApiForWriteEvents(clientAPIEvents, store));
}

initReactApp(document.querySelector('#mm-root'), store);
